import {createRouter, createWebHistory} from "vue-router";
import Content from "./components/Content.vue";
import PageStay from "@/components/page-stay.vue";
import PageHousePictures from "./components/page-house-pictures.vue";
import NewLocationComponent from "@/components/new-location-component.vue";

const routes = [
    {
        path: "/activity",
        component: Content,
        name: "activity",
        props: {locationType: "a"},
    },
    {
        path: "/dining",
        component: Content,
        name: "dining",
        props: {locationType: "r"},
    },
    {
        path: "/shopping",
        component: Content,
        name: "shopping",
        props: {locationType: "s"},
    },
    {
        path: "/stay",
        component: PageStay,
        name: "stay",
    },
    {
        path: "/house",
        component: PageHousePictures,
        name: "house",
    },
    {
        path: "/add-new",
        component: NewLocationComponent
    },
    {
        path: "/",
        component: Content,
        name: "all",
        props: {locationType: "all"},
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL), // Note the change here from mode: 'history' to using createWebHistory
    routes,
});

export default router;
