<template>
  <nav id="main-nav" class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <img alt="Sandpiper" src="../assets/Sandpiper.png" class="guide">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              @click="toggleVisibility">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="d-flex">
        <template v-if="isLoading">
          <div class="spinner-border text-dark" role="status">
<!--            <span class="sr-only">Loading...</span>-->
          </div>
        </template>
        <template v-else>
          <button v-if="!isAuthenticated" class="btn btn-outline-dark" type="button" @click="login">Login</button>
          <button v-if="isAuthenticated" class="btn btn-warning" type="button" @click="logout">Logout</button>
        </template>
      </div>
      <div class="collapse navbar-collapse" :class="{'show': visible}" id="navContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link" to="/">All</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/activity">Activities</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/dining">Dining</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/shopping">Shopping</router-link>
          </li>
          <li class="nav-item">
            <router-link v-if="isAuthenticated" class="nav-link" to="/stay">Stay</router-link>
          </li>
          <li class="nav-item">
            <router-link v-if="isAuthenticated" class="nav-link" to="/house">House</router-link>
          </li>
          <li class="nav-item">
            <router-link v-if="isAuthenticated" class="nav-link" to="/add-new">New Venue</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import {ref, onMounted} from 'vue'
import {useAuth0} from "@auth0/auth0-vue";

export default {
  name: 'Navbar',
  setup() {
    const {isAuthenticated, logout, loginWithRedirect, getAccessTokenSilently} = useAuth0()
    const visible = ref(false)
    const isLoading = ref(true);

    const toggleVisibility = () => {
      visible.value = !visible.value
    }

    onMounted(async () => {
      try {
        await getAccessTokenSilently();
      } finally {
        isLoading.value = false;
      }
    })

    return {
      visible,
      toggleVisibility,
      isAuthenticated,
      isLoading,
      logout: () => {
        logout({logoutParams: {returnTo: window.location.origin}});
      },
      login: () => {
        loginWithRedirect();
      }
    };
  }
}
</script>


<style scoped>
.guide {
  height: 20px;
  margin-right: 10px;
  margin-top: 5px;
}

nav {
  margin-top: 0;
  padding-top: 0;

}

.nav-item {
  font-weight: bold;
}

nav#main-nav {
  background-color: #a2c2f5 !important;
}
</style>