import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import './assets/global.css'

import 'bootstrap/dist/css/bootstrap.css'

import {createAuth0} from '@auth0/auth0-vue';

import Vue3Toastify from 'vue3-toastify';

const app = createApp(App);

app.use(Vue3Toastify, {
    autoClose: 3000,
});

app.use(
    createAuth0({
        domain: 'dev-cckukjp5in8bnz8o.us.auth0.com',
        clientId: 'aJOhKmZtkyqxCA3CCaautx9s6fZnsgih',
        authorizationParams: {
            redirect_uri: window.location.origin,
            audience: "https://www.obxbeachbirds.com/api",
        }
    })
);

app.use(router)

app.mount('#app')