<template>
  <div>
    <div class="container">
      <div v-if="loading" class="spinner-border" role="status">
        <span class="visually-hidden">Loading</span>
      </div>
      <div class="row">
        <div class="col-12 mt-5 mt-lg-5"></div>
      </div>
      <div v-if="!loading" class="p-4 shadow-4 rounded-3 hero row">
        <div class="container">
          <div class="row">
            <div class="col-lg countdown display-6">Just {{ stayData.daysRemaining }} more days!</div>
          </div>
          <div class="row-1 row-data">
            <div class="col-4">Check In</div>
            <div class="col">{{ formatDate(stayData.arrivalDate) }}</div>
          </div>
          <div class="row row-data">
            <div class="col-4">Check Out</div>
            <div class="col">{{ formatDate(stayData.departureDate) }}</div>
          </div>
          <div class="row row-data">
            <div class="col-4">Login Code</div>
            <div class="col">
              {{ stayData.loginCode || "Not avaliable" }}
            </div>
          </div>
          <div class="row row-data">
            <div class="col-4">House Address</div>
            <div class="col">{{ stayData.houseAddress }}</div>
          </div>
        </div>
        <div class="row">
          <a class="link-primary col-4" :href="stayData.houseLink">
            House Information Page
          </a>
          <a class="link-primary col" :href="stayData.mapsLink" target="_blank">
            Google Maps
          </a>
        </div>
      </div>
    </div>

    <div class="row mt-5"></div>

    <div v-if="!loading" class="container p-4 shadow-4 rounded-3 hero">
      <div class="row row-data">
        <div class="col">Payments</div>
        <div class="col" v-for="date in pay.dueDates" :key="date">
          {{ formatDate(date) }}
        </div>
      </div>

      <div class="row row-data" v-for="person in pay.people" :key="person.name">
        <div class="col">{{ person.name }}</div>
        <div class="col" v-for="payment in person.payments" :key="payment">
          {{ `\$ ${payment}` }}
        </div>
      </div>
    </div>

    <div class="container" v-if="!loading">
      <div class="row">
        <div class="col">
          <button @click="fetchAndDownload" class="btn btn-primary" style="margin-top: 10px">Room Assignments</button>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import {useAuth0} from "@auth0/auth0-vue";
import {onMounted, ref} from "vue";
import axios from "axios";

export default {
  setup() {
    const uri = process.env.VUE_APP_API_URL;
    const {getAccessTokenSilently} = useAuth0();
    const loading = ref(true);
    const stayData = ref("");
    const pay = ref("");

    const fetchAndDownload = async () => {
      // Fetching the file download URL from your API
      const response = await axios.get(`${uri}stay/rooms/assignments`);
      const fileUrl = await response.data.url;

      // Create a link and programmatically click it to trigger the download
      const link = document.createElement('a');
      link.href = fileUrl;
      link.target = '_blank';
      link.click();
    }

    const formatDate = (dateString) => {
      const date = new Date(dateString);

      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0 indexed
      const day = date.getDate().toString().padStart(2, "0");
      const year = date.getFullYear();

      return `${month}/${day}/${year}`;
    };

    onMounted(async () => {
      try {

        const accessToken = await getAccessTokenSilently();
        const bearerTokenHeader = `Bearer ${accessToken}`;
        const headers = {
          Authorization: bearerTokenHeader,
          "Content-Type": "application/json",
        };

        const stayResponse = await axios.get(`${uri}stay`, {headers});
        stayData.value = stayResponse.data;

        const payResponse = await axios.get(`${uri}stay/payment`, {headers});
        pay.value = payResponse.data;

        loading.value = false;
      } catch (error) {
        console.error("Failed to retrieve token:", error);
      }
    });

    return {
      loading,
      stayData,
      formatDate,
      pay,
      fetchAndDownload,
    };
  },
};
</script>
<style>
.hero {
  background-color: #a2c2f5;
}

.countdown {
  text-align: center;
}

.row-data {
  margin-bottom: 4px;
  font-weight: bold;
}
</style>
