<template>
  <div class="container">
    <div class="row">
      <location-component v-for="location in filteredLocations" :key="location.id" :venue="location"/>
    </div>
  </div>
</template>

<style scoped>

</style>
<script setup>
import { ref, computed, onMounted, defineProps } from 'vue';
import axios from 'axios';
import LocationComponent from "@/components/location-component.vue";

// Props
const props = defineProps({
  locationType: {
    type: String,
    default: 'all'
  }
});

// Ref for storing location data
const locationsData = ref([]);

// Function to fetch locations
const fetchLocations = async () => {
  try {
    const uri = process.env.VUE_APP_API_URL;
    const response = await axios.get(`${uri}Venues`);
    locationsData.value = response.data;
  } catch (error) {
    console.error("Error fetching locations:", error);
  }
};

// Computed Property
const filteredLocations = computed(() => {
  if (props.locationType === "all") {
    return locationsData.value;
  } else {
    return locationsData.value.filter(location => location.type === props.locationType);
  }
});

// Fetch data on component mount
onMounted(fetchLocations);
</script>



