<script setup>
import 'vue3-toastify/dist/index.css';
import {reactive, ref} from "vue";
import axios from "axios";
import {useAuth0} from "@auth0/auth0-vue";
import LocationComponent from "@/components/location-component.vue";
import {useRouter} from "vue-router";
import {toast} from "vue3-toastify";

const {getAccessTokenSilently} = useAuth0();

const previewUrl = ref('');
const uri = process.env.VUE_APP_API_URL;
const venue = reactive({});
const selectedOption = ref('');
const options = [
  {value: '', text: ''},
  {value: "s", text: "Shopping"},
  {value: "a", text: "Activity"},
  {value: "r", text: "Dining"},
]

const router = useRouter();


const getPreview = async () => {

  const accessToken = await getAccessTokenSilently();
  const bearerTokenHeader = `Bearer ${accessToken}`;
  const headers = {
    Authorization: bearerTokenHeader,
    "Content-Type": "application/json",
  };

  const stayResponse = await axios.get(`${uri}Venues/preview?url=${previewUrl.value}`, {headers});

  const data = stayResponse.data;

  const location = {
    title: data.title,
    description: data.description,
    imageUrl: data.image,
    url: data.url,
    ready: true
  };

  Object.assign(venue, location);
}

const createVenue = async () => {
  const accessToken = await getAccessTokenSilently();
  const bearerTokenHeader = `Bearer ${accessToken}`;
  const headers = {
    Authorization: bearerTokenHeader,
    "Content-Type": "application/json",
  };
  const pop = () => toast.error("Something went wrong. Verify the venue does not already exist.",{
    position: toast.POSITION.TOP_CENTER
  });
  
  try {
    const response = await axios.post(`${uri}Venues`, {...venue, type: selectedOption.value}, {headers});

    // Check if the status code is in the range of 200-299
    if (response.status >= 200 && response.status < 300) {
      const selectedOptionObj = options.find(option => option.value === selectedOption.value);

      console.log(selectedOptionObj.text);
      const routeTarget = selectedOptionObj.text.toLowerCase();
      await router.push({name: routeTarget});
    } else {
      console.log(response.data);
      pop();
    }
  } catch (e) {
    console.log(e);
    pop();
  }
}
</script>

<template>
  <div class="container" style="margin-top: 15px">
    <div class="form-group">
      <label for="venueUrl">Venue URL</label>
      <input type="text" class="form-control" id="venueUrl" placeholder="Enter URL of Venue" v-model="previewUrl">
      <button class="btn btn-primary" @click="getPreview">Get Preview</button>
    </div>
    <div v-if="venue.ready" class="row">
      <div class="col-12 col-md-4 order-md-1">
        <location-component :venue="venue"></location-component>
      </div>
      <div class="col-12 col-md-8 order-md-2">
        <div class="form-group">
          <label for="titleInput">Title</label>
          <input type="text" id="titleInput" class="form-control" v-model="venue.title">
          <label for="descriptionInput">Description</label>
          <input type="text" id="descriptionInput" class="form-control" v-model="venue.description">
          <label for="imageUrl">Image Url</label>
          <input type="text" id="imageUrl" class="form-control" v-model="venue.imageUrl">
        </div>
        <div class="form-group">
          <label for="venueType">Venue Type</label>
          <select class="form-control" id="venueType" v-model="selectedOption">
            <option v-for="option in options" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <button class="btn btn-primary" :disabled="!selectedOption || selectedOption === ''" @click="createVenue">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div> 
</template>

<style scoped>
.container {
  background-color: var(--primary-bg-color);
  border-radius: 5px;
}

button {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>