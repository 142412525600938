<template>
  <div>
    <div v-if="loading" class="spinner-border" role="status">
      <span class="visually-hidden">Loading</span>
    </div>
    <div v-if="!loading" class="container">
      <div id="main-carousel" class="carousel slide" >
        <div class="carousel-inner">
          <div
            v-for="image in images.images"
            :key="image.id"
            :class="{ active: isActive(image) }"
            class="carousel-item"            
          >
            <img :src="image.url" alt="House Image" class="d-block w-100" />
          </div>
        </div>

        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#main-carousel"
          data-bs-slide="prev"
          @click="prevSlide"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#main-carousel"
          data-bs-slide="next"
          @click="nextSlide"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
    <div v-if="!loading" class="container" style="padding-top: 10px">
      <div class="row image-grid overflow-scroll">
        <div
          v-for="image in images.images"
          :key="image.id"
          class="grid-item"
          @click="activateGridItem(image)"
        >
          <img
            :src="image.url"
            alt="House Image"
            class="d-block w-100"
            :class="{ gridActive: isActive(image) }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#main-carousel {
  border: solid black;
}

.image-grid {
  background-color: #a2c2f5;
  padding-top: 10px;
  max-height: 50vh;
}

.grid-item {
  height: 100%;
  width: 150px;
  margin-bottom: 2px;
}

.gridActive {
  border: solid black 4px;
  box-shadow: ivory 2px 2px;
}
</style>
<script>
import axios from "axios";
import { onMounted, ref } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  setup() {
    const { getAccessTokenSilently } = useAuth0();
    const images = ref(null);
    const loading = ref(true);

    const activateGridItem = (item) => {
      images.value.activeImageId = item.id;
    };
    const isActive = (url) => {
      return images.value.activeImageId === url.id;
    };

    const nextSlide = () => {
      const activeSlide = images.value.activeImageId;
      const slideCount = images.value.images.length;
      if (activeSlide === slideCount) images.value.activeImageId = 1;
      else images.value.activeImageId++;
    };

    const prevSlide = () => {
      const activeSlide = images.value.activeImageId;
      const slideCount = images.value.images.length;
      if (activeSlide === 1) images.value.activeImageId = slideCount;
      else images.value.activeImageId--;
    };

    onMounted(async () => {
      const uri = process.env.VUE_APP_API_URL;
      const accessToken = await getAccessTokenSilently();
      const bearerTokenHeader = `Bearer ${accessToken}`;
      const headers = {
        Authorization: bearerTokenHeader,
        "Content-Type": "application/json",
      };

      const imagesResponse = await axios.get(`${uri}stay/images`, { headers });
      console.log(imagesResponse.data);
      let index = 1;
      const urls = imagesResponse.data.imageUrls.map((url) => {
        return {
          id: index++,
          url: imagesResponse.data.baseUrl + url,
        };
      });

      images.value = {
        images: urls,
        activeImageId: 1,
      };

      loading.value = false;
    });

    return {
      images,
      loading,
      isActive,
      nextSlide,
      prevSlide,
      activateGridItem,
    };
  },
};
</script>
